'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipPortal = TooltipPrimitive.Portal;
const TooltipContent = TooltipPrimitive.Content;
const TooltipArrow = TooltipPrimitive.Arrow;

export const TooltipProvider = TooltipPrimitive.Provider;

const tooltipVariants = cva(
  'z-[1000] max-w-[300px] rounded-lg bg-black px-2.5 py-1.5 text-base text-white shadow-lg will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade motion-reduce:animate-none motion-reduce:will-change-auto',
  {
    variants: {
      multiline: {
        true: 'p-3.5',
      },
    },
  },
);

export interface TooltipProps
  extends VariantProps<typeof tooltipVariants>,
    TooltipPrimitive.TooltipProps,
    Omit<TooltipPrimitive.TooltipContentProps, 'title'>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  disabled?: boolean;
  triggerClassName?: string;
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      open,
      defaultOpen,
      delayDuration = 400,
      disableHoverableContent,
      onOpenChange,

      side,
      sideOffset = 4,
      align,
      alignOffset,
      arrowPadding,
      collisionBoundary,
      collisionPadding = 4,
      sticky,
      hideWhenDetached,
      avoidCollisions,
      onEscapeKeyDown,
      onPointerDownOutside,

      title,
      multiline: _multiline,
      disabled,
      hidden,
      className,
      triggerClassName,
      children,

      ...restProps
    },
    ref,
  ) => {
    if (hidden) {
      return null;
    }

    if (disabled || !title) {
      return children as React.JSX.Element;
    }

    const asChild = typeof children !== 'string';
    const multiline = _multiline ?? (typeof title === 'string' ? title.length >= 32 : true);

    return (
      <TooltipRoot
        open={title ? open : false}
        defaultOpen={defaultOpen}
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger
          type="button"
          asChild={asChild}
          className={asChild ? undefined : triggerClassName}
          data-cy="tooltip-trigger"
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            ref={ref}
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            arrowPadding={arrowPadding}
            collisionBoundary={collisionBoundary}
            collisionPadding={collisionPadding}
            sticky={sticky}
            hideWhenDetached={hideWhenDetached}
            avoidCollisions={avoidCollisions}
            onEscapeKeyDown={onEscapeKeyDown}
            onPointerDownOutside={onPointerDownOutside}
            className={twMerge(tooltipVariants({ multiline, className }))}
            data-cy="tooltip-content"
            {...restProps}
          >
            {title}
            {multiline ? (
              <TooltipArrow width={12} height={6} className="fill-black" data-cy="tooltip-arrow" />
            ) : null}
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    );
  },
);

Tooltip.displayName = 'Tooltip';
