import { debounce } from 'lodash';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const StepFormContainer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    currentStep: number;
    render: (props: { isHeaderSticky: boolean; isFooterSticky: boolean }) => React.ReactNode;
  }
> = ({ currentStep, render, className, ...restProps }) => {
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const [isFooterSticky, setFooterSticky] = useState(false);
  const callbackRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setFooterSticky(node.scrollHeight > node.clientHeight);
    }
    innerRef.current = node;
  }, []);

  const debouncedScrollHandler = useMemo<React.UIEventHandler<HTMLDivElement>>(() => {
    return debounce((event) => {
      const dialogContainer = innerRef.current;

      if (dialogContainer) {
        const { scrollHeight, clientHeight, scrollTop } = dialogContainer;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        startTransition(() => {
          setHeaderSticky(scrollTop > 1);
          setFooterSticky(scrollBottom > 1);
        });
      }
    }, 60);
  }, []);

  const handleDialogContainerScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
    (event) => {
      debouncedScrollHandler(event);
    },
    [debouncedScrollHandler],
  );

  useEffect(() => {
    const scrollElement = innerRef.current;
    if (scrollElement) {
      scrollElement.scroll({
        top: 0,
        behavior: 'smooth',
      });
      setFooterSticky(scrollElement.scrollHeight > scrollElement.clientHeight);
    }
  }, [currentStep]);

  return (
    <div
      ref={callbackRef}
      onScroll={handleDialogContainerScroll}
      className={twMerge('flex h-full w-full flex-col overflow-y-auto bg-grey-lightest', className)}
      {...restProps}
    >
      {render({
        isHeaderSticky,
        isFooterSticky,
      })}
    </div>
  );
};

StepFormContainer.displayName = 'StepFormContainer';
