import { ArrowLeft2Icon } from '@paid-ui/icons/arrow-left-2';
import { ArrowRight2Icon } from '@paid-ui/icons/arrow-right-2';
import { FastForwardIcon } from '@paid-ui/icons/fast-forward';
import { type FormStepper } from '@paid-ui/types/form';
import { cva } from 'class-variance-authority';
import { isNil } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '../button';
import { IconButton } from '../icon-button';
import { Tooltip } from '../tooltip';

const footerVariants = cva(
  'sticky bottom-0 z-20 empty:hidden w-full border-t gap-x-2 border-grey-hover shrink-0 bg-white flex items-center',
  {
    variants: {
      sticky: {
        true: 'border-grey-mid bg-white/80 backdrop-blur-[5px]',
      },
      variant: {
        form: 'p-2.5 justify-center',
        info: 'py-2.5 sm:px-9 px-6 justify-end',
      },
    },
  },
);

export interface StepFormFooterProps<T extends number = number>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  steps?: FormStepper<T>[];
  currentStep?: T;
  submitStep?: T;
  summaryStep?: T;
  nextText?: string;
  submitText?: string;
  skipToSummary?: boolean;
  skipText?: string;
  sticky?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  disabledNext?: boolean;
  disabledPrev?: boolean;
  disabledSubmit?: boolean;
  hideNext?: boolean;
  hidePrev?: boolean;
  variant?: 'form' | 'info';
  onPrev?: React.MouseEventHandler<HTMLButtonElement>;
  onNext?: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  onStepChange?: (step: T) => void;
}

const StepFormFooter = forwardRef(
  <T extends number = number>(
    {
      steps = [],
      currentStep = 0 as T,
      submitStep,
      summaryStep = submitStep,
      nextText: nextTextProp,
      submitText = 'Submit',
      skipToSummary,
      skipText = 'Skip to summary',
      sticky,
      isSubmitting,
      disabled,
      disabledPrev,
      disabledNext,
      disabledSubmit,
      hideNext,
      hidePrev,
      variant = 'form',
      onPrev,
      onNext,
      onSubmit,
      onStepChange,
      hidden,
      className,
      children,
      ...restProps
    }: StepFormFooterProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [isSummaryStepTouched, setSummaryStepTouched] = useState(false);

    useEffect(() => {
      if (currentStep === summaryStep) {
        setSummaryStepTouched(true);
      }
    }, [currentStep, summaryStep]);

    if (hidden) {
      return null;
    }

    if (variant === 'info') {
      return (
        <footer
          ref={ref}
          className={twMerge(footerVariants({ sticky, variant, className }))}
          {...restProps}
        >
          {children}
        </footer>
      );
    }

    const currentStepper = steps[currentStep];

    if (!currentStepper) {
      return (
        <footer
          ref={ref}
          className={twMerge(footerVariants({ sticky, variant, className }))}
          {...restProps}
        >
          <div className="space-x-2.5 rounded-full bg-white p-1.5 shadow-lg">{children}</div>
        </footer>
      );
    }

    const nextText = nextTextProp ?? currentStepper.nextText;
    const isSubmitStep = isNil(submitStep) ? false : submitStep === currentStep;
    const showSkipToSummary =
      skipToSummary && summaryStep && currentStep !== summaryStep && isSummaryStepTouched;

    return (
      <footer
        ref={ref}
        className={twMerge(footerVariants({ sticky, variant, className }))}
        {...restProps}
      >
        <div className="grid grid-flow-col items-center gap-x-1 rounded-full bg-white p-1.5 shadow-lg">
          {children}
          <Tooltip title="Previous" hidden={hidePrev}>
            <IconButton
              size="xl"
              variant="outline"
              disabled={disabledPrev || disabled}
              tabIndex={-1}
              data-current={currentStep}
              onClick={onPrev}
              className="rounded-full"
              hidden={hidePrev}
            >
              <ArrowLeft2Icon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
          {isSubmitStep ? (
            <Button
              pill
              type="submit"
              variant="solid"
              loading={isSubmitting}
              onClick={onSubmit}
              className="h-[44px]"
              disabled={disabledSubmit || disabled}
            >
              {submitText}
            </Button>
          ) : nextText ? (
            <Button
              pill
              variant="solid"
              disabled={disabledNext || disabled}
              tabIndex={-1}
              data-current={currentStep}
              onClick={onNext}
              className="h-[44px]"
            >
              {nextText}
            </Button>
          ) : (
            <Tooltip title="Next" hidden={hideNext}>
              <IconButton
                size="xl"
                variant="solid"
                disabled={disabledNext || disabled}
                tabIndex={-1}
                data-current={currentStep}
                onClick={onNext}
                className="rounded-full"
              >
                <ArrowRight2Icon className="h-4 w-4" />
              </IconButton>
            </Tooltip>
          )}
          {showSkipToSummary ? (
            <Tooltip title={skipText}>
              <IconButton
                size="xl"
                variant="outline"
                disabled={disabledNext || disabled}
                tabIndex={-1}
                onClick={() => onStepChange?.(summaryStep)}
                className="rounded-full"
              >
                <FastForwardIcon className="h-4 w-4" />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </footer>
    );
  },
);

StepFormFooter.displayName = 'StepFormFooter';

export default StepFormFooter as <T extends number = number>(
  props: StepFormFooterProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => JSX.Element;
