import { IconButton } from '@paid-ui/components/icon-button';
import { Logo } from '@paid-ui/components/logo';
import { Tooltip } from '@paid-ui/components/tooltip';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { useMediaQuery } from '@paid-ui/hooks/use-media-query';
import { ArrowRight2Icon } from '@paid-ui/icons/arrow-right-2';
import { HelpIcon } from '@paid-ui/icons/help';
import { type FormStepper } from '@paid-ui/types/form';
import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { DrawerClose } from '../drawer';

const headerVariants = cva(
  'sticky z-20 border-b border-grey-hover top-0 w-full shrink-0 bg-white',
  {
    variants: {
      sticky: {
        true: 'border-grey-mid bg-white/80 backdrop-blur-[5px]',
      },
      simple: {
        true: 'h-[60px] w-full',
      },
    },
  },
);

const progressVariants = cva(
  'inline-block h-1 cursor-pointer bg-grey-mid transition-[colors,transform] first:rounded-l-sm last:rounded-r-sm hover:scale-y-150 hover:bg-grey-tertiary first:hover:rounded-l last:hover:rounded-r disabled:pointer-events-none',
  {
    variants: {
      active: {
        true: 'bg-blue hover:bg-blue-dark',
      },
    },
  },
);

export interface StepFormHeaderProps<T extends number = number>
  extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  steps?: FormStepper<T>[];
  currentStep?: T;
  disabled?: boolean;
  simple?: boolean;
  showProgress?: boolean;
  showHelp?: boolean;
  helpUrl?: string;
  sticky?: boolean;
  preventClose?: boolean;
  titleExtra?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onStepChange?: (step: T) => void;
  onGetHelp?: React.MouseEventHandler<HTMLButtonElement>;
}

const StepFormHeader = forwardRef(
  <T extends number = number>(
    {
      title: defaultTitle,
      steps = [],
      currentStep = 0 as T,
      disabled: allDisabled,
      simple,
      showProgress,
      showHelp,
      helpUrl = 'https://appsintegration.xecurify.com/jsm/paid-inc/customer/portal/1',
      sticky,
      preventClose,
      onClose,
      onStepChange,
      onGetHelp,
      hidden,
      className,
      children,
      titleExtra,
      ...restProps
    }: StepFormHeaderProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const smallScreen = useMediaQuery('(max-width: 640px)');

    if (hidden) {
      return (
        <DrawerClose
          data-current={currentStep}
          onClick={onClose}
          tabIndex={-1}
          preventClose={preventClose}
          className="absolute left-[20] right-5 top-[18px] z-[410] sm:left-[30px] sm:top-5"
        />
      );
    }

    if (simple) {
      return (
        <header className={headerVariants({ sticky, simple, className })} {...restProps}>
          <DrawerClose
            data-current={currentStep}
            onClick={onClose}
            tabIndex={-1}
            className="left-[36px] top-[18px]"
            preventClose={preventClose}
          />
        </header>
      );
    }

    const currentStepper = steps.find((step) => step.index === currentStep);
    const title = currentStepper?.title || defaultTitle;
    const description = currentStepper?.description;

    const handleStepChange = (event: React.MouseEvent<HTMLButtonElement>) => {
      const { step } = event.currentTarget.dataset;
      const stepNumber = step ? Number.parseInt(step, 10) : Number.NaN;
      if (Number.isNaN(stepNumber)) return;
      onStepChange?.(stepNumber as T);
    };

    if (smallScreen) {
      return (
        <header ref={ref} className={twMerge(headerVariants({ sticky, className }))} {...restProps}>
          <div className="grid w-full grid-cols-[25px_1fr_28px_28px] gap-x-2 gap-y-3 px-6 py-[18px]">
            <Logo symbolOnly width={25} className="col-span-1 col-start-1" />
            <div
              className={twMerge('col-start-2 space-y-0.5', showHelp ? 'col-span-1' : 'col-span-2')}
            >
              <h2 className="line-clamp-1 text-base text-grey-dark">{title}</h2>
              {description ? (
                <p className="line-clamp-2 text-base font-medium empty:hidden">{description}</p>
              ) : null}
            </div>

            <Tooltip title="Get support">
              {onGetHelp ? (
                <IconButton
                  size="lg"
                  variant="subtle"
                  data-current={currentStep}
                  onClick={onGetHelp}
                  hidden={!showHelp}
                  className="col-span-1 col-start-3 text-grey-tertiary"
                >
                  <HelpIcon />
                </IconButton>
              ) : (
                <IconButton
                  as="a"
                  size="lg"
                  variant="subtle"
                  href={helpUrl}
                  target="_blank"
                  hidden={!showHelp}
                  className="col-span-1 col-start-3 text-grey-tertiary"
                >
                  <HelpIcon />
                </IconButton>
              )}
            </Tooltip>
            <DrawerClose
              data-current={currentStep}
              onClick={onClose}
              tabIndex={-1}
              className="col-span-1 col-start-4"
              preventClose={preventClose}
            />
            {showProgress ? (
              <div className="col-span-4 col-start-1 grid w-full grid-flow-col gap-x-[1px]">
                {steps
                  .filter((step) => !step.skip)
                  .map(({ index, description, disabled }) => (
                    <Tooltip
                      key={index}
                      disabled={allDisabled || disabled || !description}
                      title={description}
                    >
                      <UnstyledButton
                        tabIndex={-1}
                        data-step={index}
                        disabled={allDisabled || disabled}
                        onClick={handleStepChange}
                        className={twMerge(
                          progressVariants({
                            active: index <= currentStep,
                          }),
                        )}
                      />
                    </Tooltip>
                  ))}
              </div>
            ) : null}
          </div>
          {children}
        </header>
      );
    }

    return (
      <header className={headerVariants({ sticky, className })} {...restProps}>
        <DrawerClose
          data-current={currentStep}
          onClick={onClose}
          tabIndex={-1}
          className="left-[36px] top-[18px]"
          preventClose={preventClose}
        />
        <div className="grid w-full space-y-3.5 px-[36px] py-[18px]">
          {description ? (
            <h2 className="flex h-6 w-full items-center justify-center gap-x-1.5 text-center text-sm">
              <span className="text-grey-dark">{title}</span>
              <ArrowRight2Icon className="h-3 w-3 text-grey-dark" />
              <span className="font-medium">{description}</span>
              <div className="absolute right-[35px] top-5">{titleExtra}</div>
            </h2>
          ) : (
            <h2 className="flex h-6 w-full items-center justify-center gap-x-1.5 text-center text-sm font-medium">
              {title}
            </h2>
          )}
          {showProgress ? (
            <div className="grid w-full grid-flow-col gap-x-[1px]">
              {steps
                .filter((step) => !step.skip)
                .map(({ index, description, disabled }) => (
                  <Tooltip
                    key={index}
                    disabled={allDisabled || disabled || !description}
                    title={description}
                  >
                    <UnstyledButton
                      tabIndex={-1}
                      data-step={index}
                      disabled={allDisabled || disabled}
                      onClick={handleStepChange}
                      className={twMerge(
                        progressVariants({
                          active: index <= currentStep,
                        }),
                      )}
                    />
                  </Tooltip>
                ))}
            </div>
          ) : null}
        </div>
        {children}
      </header>
    );
  },
);

StepFormHeader.displayName = 'StepFormHeader';

export default StepFormHeader as <T extends number = number>(
  props: StepFormHeaderProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => JSX.Element;
