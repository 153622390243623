import { type FlyoutType, saveFlyoutState } from '@paid-ui/blocks/global-flyout';
import { ContractType, UserConsentId } from '@paid-ui/constants';
import { ContractTemplate } from '@paid-ui/enums/contract';
import { type UserGroupRelation } from '@paid-ui/enums/user';
import { type CSS, type DialogSize } from '@paid-ui/ui';
import Router from 'next/router';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export enum DialogType {
  NONE = 'NONE',
  FEATURE_TOGGLE = 'FEATURE_TOGGLE',

  LINKED_PAYMENTS = 'LINKED_PAYMENTS',
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  NEW_CONTRACT = 'NEW_CONTRACT',
  REVIEW_CONTRACT = 'REVIEW_CONTRACT',
  SECURE_PAYMENT = 'SECURE_PAYMENT',
  SECURE_VARIATION = 'SECURE_VARIATION',
  NEW_VARIATION = 'NEW_VARIATION',
  AMEND_VARIATION = 'AMEND_VARIATION',
  REVIEW_VARIATION = 'REVIEW_VARIATION',
  NEW_ADJUSTMENT = 'NEW_ADJUSTMENT',
  AMEND_ADJUSTMENT = 'AMEND_ADJUSTMENT',
  REVIEW_ADJUSTMENT = 'REVIEW_ADJUSTMENT',
  NEW_LINKING = 'NEW_LINKING',
}

export interface DialogState {
  type: DialogType;
  pathname: string;
  searchParams: string;
  data?: Record<string, any> | null;
}

interface DialogManagerDetails {
  nested?: boolean;
  size?: DialogSize;
  closeOnClickOutside?: boolean;
  closeOnEscapeKeyDown?: boolean;
  dialogStyle?: CSS;
  closeCallback?: () => void;
  [key: string]: any;
}

export const dialogManager = proxy({
  open: false,
  type: DialogType.NONE,
  stack: [] as DialogState[],
  details: {} as DialogManagerDetails,
});

devtools(dialogManager, {
  name: 'Global Dialog',
  enabled: false,
});

export const openDialog = <T extends DialogManagerDetails>(type: DialogType, details?: T) => {
  dialogManager.open = true;
  dialogManager.type = type;
  if (details !== undefined) {
    dialogManager.details = details;
  }
};

export const saveCurrentDialogState = (type: DialogType, data?: Record<string, any> | null) => {
  dialogManager.stack.push({
    type,
    pathname: window.location.pathname,
    searchParams: new URLSearchParams(window.location.search).toString(),
    data,
  });
};

export const getCurrentDialogState = (type?: DialogType) => {
  const dialog = dialogManager.stack.pop();
  if (!dialog) return null;
  if (type && dialog.type !== type) return null;
  return dialog;
};

export const restorePreviousDialog = () => {
  const previousDialog = dialogManager.stack.at(-1);
  if (!previousDialog) return;
  const { pathname, searchParams } = previousDialog;
  Router.replace([pathname, searchParams].join('?'), undefined, {
    shallow: true,
  });
};

export const closeDialog = () => {
  dialogManager.open = false;
  dialogManager.type = DialogType.NONE;
  dialogManager.stack = [];
  dialogManager.details = {};
};

export const openNewAdjustmentDialog = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', 'new-adjustment');
  urlSearchParams.sort();
  Router.replace(
    {
      pathname: window.location.pathname,
      search: urlSearchParams.toString(),
    },
    undefined,
    {
      shallow: true,
    },
  );
};

export const openAmendAdjustmentDialog = (adjustmentId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', 'amend-adjustment');
  urlSearchParams.set('adjustment', adjustmentId);
  urlSearchParams.sort();
  Router.replace(
    {
      pathname: window.location.pathname,
      search: urlSearchParams.toString(),
    },
    undefined,
    {
      shallow: true,
    },
  );
};

export const openReviewAdjustmentDialog = (adjustmentId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', 'review-adjustment');
  urlSearchParams.set('adjustment', adjustmentId);
  urlSearchParams.sort();
  Router.replace(
    {
      pathname: window.location.pathname,
      search: urlSearchParams.toString(),
    },
    undefined,
    {
      shallow: true,
    },
  );
};

export const openNewLinkingDialog = (progressPaymentId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', 'new-linking');
  urlSearchParams.set('paymentId', progressPaymentId);
  urlSearchParams.sort();
  Router.replace([window.location.pathname, urlSearchParams.toString()].join('?'), undefined, {
    shallow: true,
  });
};

export const openNewOffPlatformContractDialog = () => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('dialog', 'new-contract');
  urlSearchParams.set('template', 'off_platform');
  urlSearchParams.sort();
  Router.replace([window.location.pathname, urlSearchParams.toString()].join('?'), undefined, {
    shallow: true,
  });
};

export const openReviewContractDialog = (contractId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete('callback');
  urlSearchParams.set('dialog', 'review-contract');
  urlSearchParams.set('contractId', contractId);
  urlSearchParams.sort();
  Router.replace([window.location.pathname, urlSearchParams.toString()].join('?'), undefined, {
    shallow: true,
  });
};

export const openEditSettingsDialog = (
  type: string,
  options?: { relation?: UserGroupRelation },
) => {
  const url = new URL(window.location.href);
  url.searchParams.set('dialog', type);

  if (options?.relation) {
    url.searchParams.set('relation', options.relation);
  }

  url.searchParams.sort();
  Router.replace(url.toString(), undefined, {
    shallow: true,
    scroll: false,
  });
};

export const openFeatureToggleDialog = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', 'feature-toggle');
  urlSearchParams.sort();
  Router.replace(
    [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
    undefined,
    {
      shallow: true,
      scroll: true,
    },
  );
};

export const openDraftContractEditDialog = (
  projectId: string,
  contractId: string,
  options: {
    contractType: ContractType;
    contractTemplate: ContractTemplate | '';
  },
) => {
  if (options.contractTemplate === ContractTemplate.SUPER_CONTRACT) {
    Router.push(
      `/projects/${projectId}?contractId=${contractId}&dialog=new-super-contract&draft=1`,
    );
    return;
  }

  switch (options.contractType) {
    case ContractType.HEAD_CONTRACT: {
      Router.push(`/projects/${projectId}?contractId=${contractId}&dialog=new-contract&draft=1`);
      break;
    }

    case ContractType.SUBCONTRACT: {
      Router.push(`/projects/${projectId}?contractId=${contractId}&dialog=new-subcontract&draft=1`);
      break;
    }

    case ContractType.SUPPLY_CONTRACT: {
      Router.push(
        `/projects/${projectId}?contractId=${contractId}&dialog=new-supply-contract&draft=1`,
      );
      break;
    }

    default: {
      break;
    }
  }
};

export const openServiceAgreementDialog = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('consentId', UserConsentId.SERVICE_AGREEMENT);
  urlSearchParams.sort();
  Router.replace(
    [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
    undefined,
    {
      shallow: true,
      scroll: true,
    },
  );
};

export const openPrivacyPolicyDialog = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('consentId', UserConsentId.PRIVACY_POLICY);
  urlSearchParams.sort();
  Router.replace(
    [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
    undefined,
    {
      shallow: true,
      scroll: true,
    },
  );
};

export const closeConsentDialog = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete('consentId');
  Router.replace(
    [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
    undefined,
    {
      shallow: true,
      scroll: true,
    },
  );
};

export const openStackedFlyout = (type: FlyoutType, savePrevState = false) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (savePrevState) {
    saveFlyoutState(urlSearchParams.get('dialog') as FlyoutType);
  }
  urlSearchParams.set('dialog', type.toLowerCase().replace('_', '-'));

  Router.replace(
    [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
    undefined,
    {
      shallow: true,
      scroll: false,
    },
  );
};
