export const ArrowLeft2Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M16.0001 22C15.735 21.9997 15.4808 21.8942 15.2934 21.7066L6.29343 12.7066C6.10616 12.5191 6.00098 12.265 6.00098 12C6.00098 11.735 6.10616 11.4808 6.29343 11.2933L15.2934 2.29329C15.483 2.11665 15.7337 2.02049 15.9928 2.02506C16.2519 2.02963 16.499 2.13458 16.6823 2.3178C16.8655 2.50101 16.9704 2.74819 16.975 3.00726C16.9796 3.26633 16.8834 3.51706 16.7068 3.70662L8.41343 12L16.7068 20.2933C16.894 20.4808 16.9992 20.735 16.9992 21C16.9992 21.265 16.894 21.5191 16.7068 21.7066C16.5194 21.8942 16.2652 21.9997 16.0001 22Z" />
    </svg>
  );
};
