export const ArrowRight2Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M8.0001 2C8.26522 2.00023 8.51941 2.10574 8.70676 2.29333L17.7068 11.2933C17.894 11.4808 17.9992 11.735 17.9992 12C17.9992 12.265 17.894 12.5192 17.7068 12.7067L8.70676 21.7067C8.5172 21.8833 8.26647 21.9795 8.0074 21.9749C7.74833 21.9703 7.50115 21.8654 7.31793 21.6822C7.13472 21.4989 7.02977 21.2518 7.0252 20.9927C7.02063 20.7336 7.11679 20.4829 7.29343 20.2933L15.5868 12L7.29343 3.70667C7.10616 3.51917 7.00098 3.265 7.00098 3C7.00098 2.735 7.10616 2.48083 7.29343 2.29333C7.48079 2.10574 7.73497 2.00023 8.0001 2Z" />
    </svg>
  );
};
