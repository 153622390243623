import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '../spinner';

export interface LoadingIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {}

export const LoadingIndicator = forwardRef<HTMLDivElement, LoadingIndicatorProps>(
  ({ hidden, className, children, ...restProps }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          'flex h-full w-full select-none flex-col items-center justify-center gap-y-4 text-lg text-grey-dark',
          className,
        )}
        {...restProps}
      >
        <Spinner className="h-8 w-8 border-blue border-b-grey-mid border-l-grey-mid" />
        {children}
      </div>
    );
  },
);

LoadingIndicator.displayName = 'LoadingIndicator';
