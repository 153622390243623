import { type FormStepper } from '@paid-ui/types/form';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface StepFormContentProps<T extends number = number>
  extends React.HTMLAttributes<HTMLDivElement> {
  steps?: FormStepper<T>[];
  currentStep?: T;
}

export const StepFormContent = forwardRef<HTMLDivElement, StepFormContentProps>(
  ({ steps = [], currentStep = 0, hidden, children, className, ...restProps }, ref) => {
    if (hidden) {
      return null;
    }

    const currentStepper = steps[currentStep];

    return (
      <div
        className={twMerge(
          'container z-10 flex max-w-[752px] flex-1 flex-col items-center justify-center p-6 sm:px-9 sm:py-8',
          className,
          currentStepper?.className,
        )}
        {...restProps}
      >
        {children}
      </div>
    );
  },
);

StepFormContent.displayName = 'StepFormContent';
