'use client';

import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { cloneElement, forwardRef, isValidElement, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonGroupContext } from '../button-group';
import { Spinner } from '../spinner';

const buttonVariants = cva(
  'relative inline-flex cursor-pointer select-none appearance-none items-center justify-center border border-solid border-transparent font-medium no-underline outline-none transition-all duration-150 ease-linear focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue/50 active:scale-[0.97] disabled:cursor-not-allowed disabled:shadow-none disabled:transition-none disabled:active:transform-none',
  {
    variants: {
      size: {
        xs: 'h-[18px] w-[18px] rounded text-xs',
        sm: 'h-[22px] w-[22px] rounded text-sm',
        md: 'h-[28px] w-[28px] rounded-md text-lg',
        lg: 'h-[34px] w-[34px] rounded-lg text-2xl',
        xl: 'h-[44px] w-[44px] rounded-lg text-3xl',
      },
      variant: {
        default:
          'border-grey-mid bg-white text-black shadow-sm hover:bg-grey-light active:bg-grey-light disabled:bg-grey-light disabled:text-grey-tertiary',
        solid:
          'bg-blue text-white shadow-sm hover:bg-blue-dark active:bg-blue-dark disabled:bg-grey-tertiary disabled:text-white',
        outline:
          'border-blue bg-transparent text-blue hover:border-blue-dark hover:bg-tint-light hover:text-blue-dark active:border-blue-dark active:bg-tint-light active:text-blue-dark disabled:border-grey-mid disabled:bg-grey-light disabled:text-grey-tertiary',
        light:
          'bg-grey-light text-blue hover:bg-tint-light hover:text-blue-dark active:bg-tint-light active:text-blue-dark disabled:bg-grey-light disabled:text-grey-tertiary',
        subtle:
          'bg-transparent text-blue hover:bg-tint-light hover:text-blue-dark active:bg-tint-light active:text-blue-dark disabled:bg-transparent disabled:text-grey-tertiary',
        link: 'bg-transparent text-blue underline-offset-4 hover:text-blue-dark hover:underline active:text-blue-dark active:underline disabled:text-grey-tertiary disabled:no-underline',
      },
      danger: {
        true: '',
      },
      disabled: {
        true: 'cursor-not-allowed shadow-none transition-none active:transform-none',
      },
      loading: {
        true: '',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        danger: true,
        className: 'text-pink hover:text-pink-dark active:text-pink-dark',
      },
      {
        variant: 'solid',
        danger: true,
        className: 'bg-pink hover:bg-pink-dark active:bg-pink-dark',
      },
      {
        variant: 'outline',
        danger: true,
        className:
          'border-pink text-pink hover:border-pink-dark hover:bg-pink-light hover:text-pink-dark active:border-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'light',
        danger: true,
        className:
          'bg-grey-light text-pink hover:bg-pink-light hover:text-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'subtle',
        danger: true,
        className:
          'text-pink hover:bg-pink-light hover:text-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'link',
        danger: true,
        className: 'text-pink hover:text-pink-dark active:text-pink-dark',
      },
      {
        variant: 'default',
        disabled: true,
        className: 'bg-grey-light text-grey-tertiary',
      },
      {
        variant: 'solid',
        disabled: true,
        className:
          'bg-grey-tertiary text-white hover:bg-grey-tertiary hover:text-white active:bg-grey-tertiary active:text-white',
      },
      {
        variant: 'outline',
        disabled: true,
        className:
          'border-grey-mid bg-grey-light text-grey-tertiary hover:border-grey-mid hover:bg-grey-light hover:text-grey-tertiary active:border-grey-mid active:bg-grey-light active:text-grey-tertiary',
      },
      {
        variant: 'light',
        disabled: true,
        className:
          'bg-grey-light text-grey-tertiary hover:bg-grey-light hover:text-grey-tertiary active:bg-grey-light active:text-grey-tertiary',
      },
      {
        variant: 'subtle',
        disabled: true,
        className:
          'bg-transparent text-grey-tertiary hover:bg-transparent hover:text-grey-tertiary active:bg-transparent active:text-grey-tertiary',
      },
      {
        variant: 'link',
        disabled: true,
        className:
          'text-grey-tertiary no-underline hover:text-grey-tertiary hover:no-underline active:text-grey-tertiary active:no-underline',
      },
    ],
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  },
);

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorAttributes = React.ComponentPropsWithRef<typeof Link>;

interface NativeIconButtonProps
  extends VariantProps<typeof buttonVariants>,
    Omit<ButtonAttributes, 'disabled' | 'size'> {
  as?: 'button';
}

interface LinkIconButtonProps
  extends VariantProps<typeof buttonVariants>,
    Omit<AnchorAttributes, 'size'> {
  as: 'a';
}

export type IconButtonProps = NativeIconButtonProps | LinkIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (
    {
      as = 'button',

      size,
      variant,
      danger,
      disabled: _disabled,
      loading,

      hidden,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const buttonGroup = useContext(ButtonGroupContext);
    const disabled = Boolean(buttonGroup.disabled || _disabled || loading);

    if (hidden) {
      return null;
    }

    const mergedClassName = twMerge(
      buttonVariants({
        size: size ?? buttonGroup.size,
        variant: variant ?? buttonGroup.variant,
        danger,
        disabled: as === 'button' ? null : disabled,
        loading,
        className,
      }),
    );

    const dom = loading ? (
      <Spinner />
    ) : isValidElement<React.SVGAttributes<SVGElement>>(children) ? (
      cloneElement(children, {
        ...children.props,
        'aria-hidden': 'true',
        'className': twMerge('h-[1em] w-[1em] shrink-0 text-current', children?.props?.className),
      })
    ) : (
      children
    );

    if (as === 'a') {
      const { href, ...restProps } = props as AnchorAttributes;

      if (disabled || !href) {
        return (
          <a
            ref={ref as React.Ref<HTMLAnchorElement>}
            aria-disabled="true"
            aria-invalid="true"
            className={mergedClassName}
            {...restProps}
          >
            {dom}
          </a>
        );
      }

      return (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={href}
          className={mergedClassName}
          {...restProps}
        >
          {dom}
        </Link>
      );
    }

    const { type = 'button', ...restProps } = props as ButtonAttributes;

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        aria-disabled={disabled ? 'true' : undefined}
        className={mergedClassName}
        {...restProps}
      >
        {dom}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
