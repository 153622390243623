export const HelpIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M12 24C9.62663 24 7.30655 23.2962 5.33316 21.9776C3.35977 20.6591 1.8217 18.7849 0.913451 16.5922C0.00519943 14.3995 -0.232441 11.9867 0.230582 9.65892C0.693605 7.33115 1.83649 5.19295 3.51472 3.51472C5.19295 1.83649 7.33115 0.693605 9.65892 0.230582C11.9867 -0.232441 14.3995 0.00519943 16.5922 0.913451C18.7849 1.8217 20.6591 3.35977 21.9776 5.33316C23.2962 7.30655 24 9.62663 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24ZM12 2.00001C10.0222 2.00001 8.08879 2.5865 6.4443 3.68531C4.79981 4.78412 3.51809 6.34591 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38643 21.2388 8.17317C20.7363 6.95992 19.9997 5.85752 19.0711 4.92894C18.1425 4.00035 17.0401 3.26376 15.8268 2.76121C14.6136 2.25866 13.3132 2.00001 12 2.00001Z" />
      <path d="M12 15C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1053 14.5195 11 14.2652 11 14V12C10.9992 11.7925 11.0629 11.5899 11.1824 11.4203C11.3018 11.2507 11.4711 11.1224 11.6666 11.0533C12.2566 10.85 13.9466 10.0533 13.9466 8.99996C13.9476 8.58562 13.8198 8.18123 13.5809 7.84267C13.3421 7.5041 13.004 7.24807 12.6133 7.10996C12.0941 6.9333 11.5268 6.96435 11.03 7.19663C10.7919 7.30899 10.5784 7.46723 10.4017 7.66229C10.2249 7.85734 10.0884 8.08537 9.99998 8.33329C9.95621 8.45717 9.88846 8.57122 9.80061 8.66892C9.71276 8.76662 9.60653 8.84606 9.48798 8.9027C9.36943 8.95934 9.24089 8.99208 9.10969 8.99905C8.97848 9.00601 8.84719 8.98707 8.72331 8.94329C8.59943 8.89952 8.48539 8.83177 8.38769 8.74392C8.28999 8.65608 8.21055 8.54984 8.15391 8.43129C8.09726 8.31275 8.06452 8.1842 8.05756 8.053C8.05059 7.9218 8.06954 7.79051 8.11331 7.66663C8.29165 7.16428 8.56957 6.70312 8.93042 6.31076C9.29127 5.91841 9.72762 5.60295 10.2133 5.38329C11.1823 4.92596 12.2923 4.86849 13.3033 5.22329C14.0841 5.49933 14.7599 6.01087 15.2375 6.68734C15.7152 7.36381 15.9711 8.17185 15.97 8.99996C15.97 11 14.0666 12.1633 13.01 12.6666V14C13.01 14.1321 12.9838 14.263 12.9329 14.385C12.882 14.5069 12.8075 14.6176 12.7136 14.7106C12.6196 14.8036 12.5082 14.8771 12.3857 14.9267C12.2633 14.9764 12.1321 15.0013 12 15Z" />
      <path d="M12 18.5C12.5523 18.5 13 18.0523 13 17.5C13 16.9477 12.5523 16.5 12 16.5C11.4477 16.5 11 16.9477 11 17.5C11 18.0523 11.4477 18.5 12 18.5Z" />
    </svg>
  );
};
